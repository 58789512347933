import {useRef, useEffect, Fragment} from 'react';
import ModalDialog from './ModalDialog';

const AddCardIFrame = ({getCard, closeDialog, addCardParams, isModal}) => {

    const form = useRef(null);

    useEffect(() => {

        window.addEventListener('message', postMessage);

        form.current.submit();

        return () => { 
            window.removeEventListener('message', postMessage);
        };
        
    	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const postMessage = e => {
        
        var defaultIframeHeight = 472;

        if (e.data === 'credit-card-page-complete') {
            getCard();
            closeDialog();
        }
        if (e.data === 'credit-card-reload-page') {
        }
        if (typeof e.data == 'object' && typeof e.data.bodyHeight != 'undefined') {

            let newIframeHeight = parseInt(e.data.bodyHeight);
            let ccframe = document.getElementById('addCreditCardIframe');

            if (ccframe) {
                ccframe.height = (newIframeHeight > defaultIframeHeight ? newIframeHeight+3 : defaultIframeHeight+3) +'px';
            }

        }
    };

    return (
        <Fragment>
            {isModal ? 
                <ModalDialog 
                    isMobile={false}
                    title='Add Credit Card'
                    hasClosedButton
                    icon="icon-close"
                    classNames='is-55rem-width AddCardContainer'
                    zIndex={1000}
                    closeModal={closeDialog}
                >
                    <div className='AddCardContainer'>   
                        <div className="modal-content">
                            <iframe 
                                id="addCreditCardIframe" 
                                name="addCreditCardIframe"
                                width="100%" 
                                height="472px" 
                                frameborder="0"
                                src=""
                                title="Add Credit Card"
                            />
                            <form 
                                ref={form}
                                id="addCardSecureForm" 
                                action={addCardParams.action_url} 
                                method="post" 
                                target="addCreditCardIframe"
                            >
                                <input type="hidden" name={addCardParams.post_key} value={addCardParams.post_data}/>
                            </form>
                        </div>
                    
                        <style jsx global>{`
                            .AddCardContainer {
                                .modal-content {
                                    padding-top: 0 !important;
                                    margin: 0;
                                }
                            }
                            #addCreditCardIframe {
                                min-height: 35rem;
                                .button.add { 
                                    display: none;
                                }
                            }
                        `}</style>
                    </div>
                </ModalDialog>
            : 
                <div className='AddCardContainer'>   
                    <div className="modal-content">
                        <iframe 
                            id="addCreditCardIframe" 
                            name="addCreditCardIframe"
                            width="100%" 
                            height="472px" 
                            frameborder="0"
                            src=""
                            title="Add Credit Card"
                        />
                        <form 
                            ref={form}
                            id="addCardSecureForm" 
                            action={addCardParams.action_url} 
                            method="post" 
                            target="addCreditCardIframe"
                        >
                            <input type="hidden" name={addCardParams.post_key} value={addCardParams.post_data}/>
                        </form>
                    </div>
                
                    <style jsx global>{`
                        .AddCardContainer {
                            .modal-content {
                                padding-top: 0 !important;
                                margin: 0;
                            }
                        }
                        #addCreditCardIframe {
                            min-height: 35rem;
                            .button.add { 
                                display: none;
                            }
                        }
                    `}</style>
                </div>
            }
        </Fragment>
    );
};

export default AddCardIFrame;