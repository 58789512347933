import {Fragment} from "react";
import {Link} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import Media from "react-media";
import classNames from "classnames";
import {Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import routes from "../../config/routes";
import variables from "../../assets/sass/variables.scss";

const VPS = ({isMobile, isLoggedIn}) => {

  const scrollToMiddle = (el) => {
    el.scrollIntoView({ 
      behavior: 'auto', 
      block: 'center' 
    })
  }

  const sectionClasses = classNames('VPS', {
    "section": isMobile
  });

  return (
    <section className={sectionClasses}>
			<Media                                    //MOBILE
				query={{maxWidth: variables.tabletMax}}
				defaultMatches={isMobile}
			>
        <div className="main-wrapper">
          <h2 className="luck-sky mb-3">
            VPS
            <span className="has-text-marine quick-font is-block">Products</span>
          </h2>
          <p className="description-s mb-5">Virtual Private Server that anyone can set up in seconds. Simple enough for all users, powerful enough for any business.</p>
          <div className="vps-wrapper">
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              navigation
              pagination={{clickable: true}}
              onSlideChange={() => {}}
              onSwiper={() => {}}
            >
              <SwiperSlide>            
                <div className="card">
                  <img src="/images/paper-plane.svg" alt="paper-plane" className="logo" width={40} height={40}/>
                  <div className="label-wrapper">
                    <span className="is-size-2 has-text-marine luck line-height-1">BASIC</span>
                    <span className="line-height-1 ">For simple applications</span>
                  </div>
                  <ul className="ul-checkmark">
                    <li>
                      <span className="checkmark"/>
                      Low-traffic web servers
                    </li>
                    <li>
                      <span className="checkmark"/>
                      Blogs and forums
                    </li>
                    <li>
                      <span className="checkmark"/>
                      Small databases
                    </li>
                  </ul>
                  <div className="dashed"/>
                  <div className="is-flex is-align-items-normal is-justify-content-flex-end my-4">
                    from
                    <span className="luck is-size-3	has-text-marine ml-2 line-height-1">$9.95</span>
                  </div>
                  <HashLink 
                    to={`${routes.products}/#basic`} 
                    className="effect has-text-marine underlined-animated is-bold"
                    scroll={scrollToMiddle}
                  >
                    Get started with Basic &#8594;	
                  </HashLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <img src="/images/rocket.svg" alt="rocket" className="logo" width={40} height={40}/>
                  <div className="label-wrapper">
                    <span className="is-size-2 has-text-grape luck line-height-1">ADVANCED</span>
                    <span className="line-height-1 ">For larger projects</span>
                  </div>
                  <ul className="ul-checkmark">
                    <li>
                      <span className="checkmark"/>
                      Mid-traffic web servers  
                    </li>
                    <li>
                      <span className="checkmark"/>
                      E-commerce sites
                    </li>
                    <li>
                      <span className="checkmark"/>
                      Medium databases
                    </li>
                  </ul>
                  <div className="dashed"/>
                  <div className="is-flex is-align-items-normal is-justify-content-flex-end my-4">
                    from
                    <span className="luck is-size-3	has-text-grape ml-2 line-height-1">$24.95</span>
                  </div>
                  <HashLink 
                    to={`${routes.products}#advanced`} 
                    className="effect has-text-marine underlined-animated is-bold"
                    scroll={scrollToMiddle}
                  >
                    Get started with Advanced &#8594;	
                  </HashLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <img src="/images/robot.svg" alt="paper-plane" className="logo" width={40} height={40}/>
                  <div className="label-wrapper">
                    <span className="is-size-2 has-text-pink luck line-height-1">MASTER</span>
                    <span className="line-height-1 ">For huge works</span>
                  </div>
                  <ul className="ul-checkmark">
                    <li>
                      <span className="checkmark"/>
                      High-traffic web servers  
                    </li>
                    <li>
                      <span className="checkmark"/>
                      Media streaming
                    </li>
                    <li>
                      <span className="checkmark"/>
                      Large databases
                    </li>
                  </ul>
                  <div className="dashed"/>
                  <div className="is-flex is-bold is-align-items-normal is-justify-content-flex-end my-4">
                    from
                    <span className="luck is-size-3	has-text-pink ml-2 line-height-1">$49.95</span>
                  </div>
                  <HashLink 
                    to={`${routes.products}#master`} 
                    className="effect has-text-marine underlined-animated is-bold"
                    scroll={scrollToMiddle}
                  >
                    Get started with Master &#8594;	
                  </HashLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <img src="/images/gears.svg" alt="paper-plane" className="logo" width={40} height={40}/>
                  <div className="label-wrapper">
                    <span className="is-size-2 has-text-sky luck line-height-1">Custom</span>
                    <span className="line-height-1 ">For flexible needs</span>
                  </div>
                  <ul className="ul-checkmark">
                    <li>
                      <span className="checkmark"/>
                      Take only what you need 
                    </li>
                    <li>
                      <span className="checkmark"/>
                      Tailored to your needs 
                    </li>
                    <li>
                      <span className="checkmark"/>
                      Pay-as-you-go
                    </li>
                  </ul>
                  <div className="dashed"/>
                  <div className="is-flex is-bold is-align-items-normal is-justify-content-flex-end my-4">
                    from
                    <span className="luck is-size-3	has-text-sky ml-2 line-height-1">$4.95</span>
                  </div>
                  <HashLink 
                    to={`${routes.products}#custom`} 
                    className="effect has-text-marine underlined-animated is-bold"
                    scroll={scrollToMiddle}
                  >
                    Get started with Custom &#8594;	
                  </HashLink>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          {!isLoggedIn && (
            <div>
              <div className="mt-6 wrap">Get started with RMBoto by logging in to your account.</div>
              <div className="button-wrapper">
                <Link to={routes.login} className="button paleblue is-large">
                  <img src="/images/sign-out-alt.svg" alt="circle-icon" width={20} height={20}/>
                  Login
                </Link>
                <Link to={routes.signup} className="button is-large">
                  <img src="/images/circle-user.svg" alt="circle-icon" width={20} height={20}/>
                  Sign up
                </Link>
              </div>
            </div>
          )}
        </div>
			</Media>

			<Media
				query={{minWidth: variables.desktopMin}}
				defaultMatches={!isMobile}
			>
        <div className="is-fullwidth">
          <h2 className="luck-sky">
            VPS
            <span className="has-text-marine quick-font is-block">Products</span>
          </h2>
          <p className="description-s mb-6">Virtual Private Cloud machines that anyone can set up in seconds. Simple enough for all users, powerful enough for any business.</p>
          <div className="vps-wrapper has-height-fit-content">
            <div className="card">
              <img src="/images/paper-plane.svg" alt="paper-plane" className="logo" width={40} height={40}/>
              <div className="label-wrapper">
                <span className="is-size-2 has-text-marine luck line-height-1">Basic</span>
                <span className="line-height-1">For simple applications</span>
              </div>
              <ul className="ul-checkmark">
                <li>
                  <span className="checkmark"/>
                  Low-traffic web servers
                </li>
                <li>
                  <span className="checkmark"/>
                  Blogs and forums
                </li>
                <li>
                  <span className="checkmark"/>
                  Small databases
                </li>
              </ul>
              <div className="dashed"/>
              <div className="is-flex is-align-items-normal is-justify-content-flex-end my-4">
                from
                <span className="luck is-size-3	has-text-marine ml-2 line-height-1">$9.95</span>
              </div>
              <HashLink 
                to={`${routes.products}/#basic`} 
                className="effect has-text-marine underlined-animated is-bold"
                scroll={scrollToMiddle}
              >
                Get started with Basic &#8594;	
              </HashLink>
            </div>
            <div className="card">
              <img src="/images/rocket.svg" alt="rocket" className="logo" width={40} height={40}/>
              <div className="label-wrapper">
                <span className="is-size-2 has-text-grape luck line-height-1">Advanced</span>
                <span className="line-height-1">For larger projects</span>
              </div>
              <ul className="ul-checkmark">
                <li>
                  <span className="checkmark"/>
                  Mid-traffic web servers  
                </li>
                <li>
                  <span className="checkmark"/>
                  E-commerce sites
                </li>
                <li>
                  <span className="checkmark"/>
                  Medium databases
                </li>
              </ul>
              <div className="dashed"/>
              <div className="is-flex is-align-items-normal is-justify-content-flex-end my-4">
                from
                <span className="luck is-size-3	has-text-grape ml-2 line-height-1">$24.95</span>
              </div>
              <HashLink 
                to={`${routes.products}#advanced`} 
                className="effect has-text-marine underlined-animated is-bold"
                scroll={scrollToMiddle}
              >
                Get started with Advanced &#8594;	
              </HashLink>
            </div>
            <div className="card">
              <img src="/images/robot.svg" alt="paper-plane" className="logo" width={40} height={40}/>
              <div className="label-wrapper">
                <span className="is-size-2 has-text-pink luck line-height-1">Master</span>
                <span className="line-height-1">For huge works</span>
              </div>
              <ul className="ul-checkmark">
                <li>
                  <span className="checkmark"/>
                  High-traffic web servers  
                </li>
                <li>
                  <span className="checkmark"/>
                  Media streaming
                </li>
                <li>
                  <span className="checkmark"/>
                  Large databases
                </li>
              </ul>
              <div className="dashed"/>
              <div className="is-flex is-align-items-normal is-justify-content-flex-end my-4">
                from
                <span className="luck is-size-3	has-text-pink ml-2 line-height-1">$49.95</span>
              </div>
              <HashLink 
                to={`${routes.products}#master`} 
                className="effect has-text-marine underlined-animated is-bold"
                scroll={scrollToMiddle}
              >
                Get started with Master &#8594;	
              </HashLink>
            </div>
            <div className="card">
              <img src="/images/gears.svg" alt="paper-plane" className="logo" width={40} height={40}/>
              <div className="label-wrapper">
                <span className="is-size-2 has-text-sky luck line-height-1">Custom</span>
                <span className="line-height-1">For flexible works</span>
              </div>
              <ul className="ul-checkmark">
                <li>
                  <span className="checkmark"/> 
                  Take only what you need  
                </li>
                <li>
                  <span className="checkmark"/>
                  Tailored to your needs
                </li>
                <li>
                  <span className="checkmark"/>
                  Pay-as-you-go
                </li>
              </ul>
              <div className="dashed"/>
              <div className="is-flex is-align-items-normal is-justify-content-flex-end my-4">
                from
                <span className="luck is-size-3	has-text-sky ml-2 line-height-1">$4.95</span>
              </div>
              <HashLink 
                to={`/products#custom`} 
                className="effect has-text-marine underlined-animated is-bold"
                scroll={scrollToMiddle}
              >
                Get started with Custom &#8594;	
              </HashLink>
            </div>
          </div>
          {!isLoggedIn && (
            <Fragment>
              <div className="mt-6">Get started with RMBoto by logging in to your account.</div>
              <div className="button-wrapper">
                <Link to={routes.login} className="button paleblue is-large">
                  <img src="/images/sign-out-alt.svg" alt="circle-icon" width={20} height={20}/>
                  Login
                </Link>
                <Link to={routes.signup} className="button is-large">
                  <img src="/images/circle-user.svg" alt="circle-icon" width={20} height={20}/>
                  Sign up
                </Link>
              </div>
            </Fragment>
          )}
        </div>
			</Media>

      <style jsx global>{`
        .VPS {
          @media all and (min-width: ${variables.widescreenMin}) and (max-width: ${variables.widescreenMax}) {
            background-color: red;
          }
          height: fit-content;
          text-align: center;
          background: #fff !important;
          padding: 1rem 1rem 2.5rem 1rem;
          .vps-wrapper {
            display: flex;
            justify-content: center;
            gap: 1rem;
            width: 100%;
            .card {  
              position: relative;
              padding: 2.5rem;
              box-shadow: 0px 0px 30px #0000001A;
              border-radius: ${variables.radiusLarge};
              width: 22.5%;
              white-space: nowrap;
              .logo {
                position: absolute;
                top: 3rem;
                left: 3rem;
              }
              .label-wrapper {
                display: flex; 
                flex-direction: column; 
                align-items: flex-start;
                margin-left: 3.5rem;
              }
              .ul-checkmark {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin-top: 2rem;
                li {
                  display: flex;
                  gap: 1rem;
                  .checkmark {
                    display: block;
                    background-image: url('/images/checkmark.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 1.5rem;
                    height: 1.5rem;
                  }
                }
              }
              .dashed {
                margin-top: 3rem;
                border: 1px dashed #A3A3A3;
              }
            }
          }
          .button-wrapper { 
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            .button {
              width: 11.25rem;
            }
            .with-icon {
              padding: 1.5rem 2.5rem;
            }
          }
          @media all and (max-width: ${variables.mobileMax}) { 
            width: 100vw;
            height: 100%;
            padding: 1rem 0;
            .main-wrapper {
              height: 100%;
              .vps-wrapper {
                width: 100vw;
                height: fit-content;
                .swiper {
                  padding: 2rem 0 2.5rem 0;
                  .card {
                    margin: 0 auto;
                    padding: 2.5rem 1.875rem;
                    width: calc(100% - 10rem);
                    .logo {
                      top: 2.5rem;
                      left: 1.875rem;
                    }
                    .label-wrapper {
                      margin-left: 3rem;
                    } 
                  }
                }
              }
            }
          }
        }
      `}</style>
    </section>
  )
};

export default VPS;