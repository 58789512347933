import React, {useEffect, useState, Fragment} from "react";
import {Link} from 'react-router-dom';
import {BarLoader} from "react-spinners";
import classNames from "classnames";
import variables from "../../assets/sass/variables.scss";
import screenVariables from "../../assets/sass/screensVariables.scss";
import {verifyPasswordResetToken, changePassword} from "../../config/ajax";
import routes from "../../config/routes";

const LandingPagePasswordReset = (props) => {
	const [data, setData] = useState({
		password: "",
		confPassword: ""
	});
	const [errors, setErrors] = useState({});
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isSuccessful, setIsSuccessful] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [passwordInputRef, setPasswordInputRef] = useState({
		icon: "eye-slash.svg",
		type: "password"
	});
	const [confirmPasswordInputRef, setConfirmPasswordInputRef] = useState({
		icon: "eye-slash.svg",
		type: "password"
	});
	const [token, setToken] = useState(null);

	useEffect(() => {
		verifyPassword();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const verifyPassword = () => {
		const search = props.location.search;
		const token = search.substring(search.lastIndexOf("=") + 1);

		verifyPasswordResetToken(token)
			.then(() => {
				setToken(token);
				setIsLoading(false);
			}) 
			.catch((error) => {
				console.log('Token is invalid!');
			});
	};

	const changePasswordInputIcon = () => {
		if (passwordInputRef.type === "password") {
			setPasswordInputRef({
				icon: "eye.svg",
				type: "text"
			})
		} else {
			setPasswordInputRef({
				icon: "eye-slash.svg",    
				type: "password"
			})
		}
	}; 

	const changeConfirmPasswordInputIcon = () => {
		if (confirmPasswordInputRef.type === "password") {
			setConfirmPasswordInputRef({
				icon: "eye.svg",
				type: "text"
			})
		} else {
			setConfirmPasswordInputRef({
				icon: "eye-slash.svg",    
				type: "password"
			})
		}
	}; 

	const handleDataChange = (e) => {
		setData({
			...data,
			[e.target.id]: e.target.value
		})
	};

	const validateForm = () => {
		const obj = {};

		if (data?.password?.length === 0) {
			obj.password = "Password is required!";
		}

		if (data?.confPassword?.length === 0) {
			obj.repeatPassword = "Confirm password is required!";
		}

		if (data?.password !== data?.confPassword) {
			obj.repeatPassword = "Passwords doesn't match!";
		}

		return obj;
	};

	const removeError = (e) => {
		setErrors(prevState => ({
			...prevState,                                            
			[e.target.dataset.name] : ''                       
		}))
	};

	const sendData = (e) => {
		e.preventDefault();
		let errors = validateForm();

		if (Object.keys(errors).length === 0) {

			setIsButtonLoading(true);

			let obj = {
				password: data.password,
				repeatPassword: data.confPassword,
				token: token
			};

			changePassword(obj)
				.then((res) => {
					setData({
						password: "", 
						confPassword: "" 
					});
					setIsSuccessful(true);
				})
				.catch((error) => {
					console.log(error);
					setIsButtonLoading(false);				
					if (error.response.status === 400) {
						setErrors(error.response.data.data);
					} else {
						if (error.response.data.data) {
							setErrors(error.response.data.data);
						}
					}
				}) 
		} else {
			setErrors(errors);
		}
	};

	const newPasswordClasses = classNames("input", {    
		"is-danger": errors.password
	});

	const confirmPasswordClasses = classNames("input", {    
		"is-danger": errors.repeatPassword
	});

	const buttonClasses = classNames("button", "blue", "is-large", 'mx-auto', {
		"is-loading": isButtonLoading
	});

	return (
		<div className="LandingPagePasswordReset">
			{isLoading ? (
				<div className="loader-wrapper">
					<BarLoader 
						color={variables.sky}
						width={250}
						height={4}
					/>
				</div>
			) : (
				<section className="section">
					{!isSuccessful ? (
						<Fragment>
							<h1 className="title has-text-centered">
								Create new
								<div className="has-text-sky luck is-block">password</div>
							</h1> 
							<form id="myForm" method="get" autoComplete="off" noValidate className="has-text-centered">
								<div className="field has-text-left">
									<label htmlFor="password" className="label">New password</label>
									<div className="control is-relative">
										<input 
											onChange={handleDataChange}
											value={data.password}
											className={newPasswordClasses}
											onFocus={removeError}
											type={passwordInputRef.type}
											id="password" 
											name="password" 
											data-name="password"
											placeholder="Enter new password"
											autoComplete="new-password"
										/>
										<img
											className="eye"
											src={"/images/" + passwordInputRef.icon}
											onClick={changePasswordInputIcon}
											alt="eye"
											width="32"
											height="32"
										/>
									</div>
									{errors.password && (<span className="errors">{errors.password}</span>)}
								</div>  
								<div className="field has-text-left">
									<label htmlFor="confPassword" className="label">Confirm new password</label>
									<div className="control">
										<input 
											onChange={handleDataChange}
											value={data.confPassword}
											className={confirmPasswordClasses}
											onFocus={removeError}
											type={confirmPasswordInputRef.type} 
											id="confPassword" 
											name="password" 
											data-name="repeatPassword"
											placeholder="Confirm new password"
											autoComplete="new-password"
										/>
										<img
											className="eye"
											src={"/images/" + confirmPasswordInputRef.icon}
											onClick={changeConfirmPasswordInputIcon}
											alt="eye"
											width="32"
											height="32"
										/>
									</div>
									{errors.repeatPassword && (<span className="errors">{errors.repeatPassword}</span>)}
								</div>
								<button 
									onClick={sendData}
									className={buttonClasses}
									type="submit" 
								>
									Submit
								</button>
								<Link className="links is-size-5-half mx-auto" to={routes.login}>Back to Login</Link>
							</form>
						</Fragment>
					) : (
						<Fragment>
							<h1 className="title has-text-centered">
								Password
								<div className="has-text-sky luck is-block">created</div>
							</h1> 
							<div className="logo-wrapper">
								<object 
									className="change-password"
									type="image/svg+xml" 
									data="/images/confirmed-token.svg"
									title="change-password"
									aria-label="change-password"
									aria-labelledby="change-password"
									width="400"
									height="400"
								/>
							</div>
							<Link to={routes.login} className="links">
								back to login
							</Link>
						</Fragment>
					)}
				</section>
			)}
			<style global jsx>{`
				.LandingPagePasswordReset {
					text-align: center;
					margin-top: ${variables.headerMargin};
					background-color: ${variables.paleblue};
					height: calc(100vh - ${variables.headerMargin} - ${variables.footerHeight});
					.section {
						form {
							width: 40%;
							margin-inline: auto;
							button {
								margin-block: 1rem;
							}
						}
					}
					@media all and (max-width: ${screenVariables.mobileMax}) {
						.section {
							form {
								width: 100%;
								margin-top: 4rem;
								margin-inline: auto;
								button {
									margin-block: 1rem;
								}
							}
						}
					}
				}
			`}</style>
		</div>
	)
};

export default LandingPagePasswordReset;