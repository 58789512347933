export const STAGES = {
    DEVELOPMENT: 'dev',
    STAGING: 'stage',
    PRODUCTION: 'prod'
};

export const TOKENS = {
    AT: 'accessToken',
    RT: 'refreshToken'
};

export const TOKENS_DURATION = {
    ONE_DAY: 1,
    ONE_WEEK: 7,
    TWO_SECONDS: 0.00002
};

export const SERVER_ERRORS = {
    ERR_BAD_REQUEST: 13008,

    ERROR_INVALID_PARAMETERS: 10010,

    ERROR_ALREADY_EXISTS: 10020,
    ERROR_ACCESS_DENIED: 10030,
    ERROR_NOT_FOUND: 10040,
    ERROR_UNABLE_TO_CREATE: 10050,
    ERROR_UNABLE_TO_DELETE: 10060,
    ERROR_UNABLE_TO_STORE: 10070,
    ERROR_UNABLE_TO_UPDATE: 10080,

    ERROR_USER_NOT_ACTIVE: 12010,
    ERROR_WRONG_EMAIL_OR_PASSWORD: 12020,
    ERROR_ACCOUNT_DELETED: 12030,
    ERROR_USER_NOT_AUTHORIZED: 12050,
    ERROR_USER_BANNED : 2060 ,

    ERROR_BAD_TOKEN: 13020,
    ERROR_TOKEN_EXPIRED: 13030,
    ERROR_TOKEN_CONFIRMED: 13040,
    ERROR_NOT_ACCEPTED: 14000,

    ERROR_NO_AVAILABLE_IP: 15030,

    ERROR_FAILED_ADD_CARD_INFO: 16010,
    ERROR_MISSING_ADD_CARD_PARAMS: 16020,
    ERROR_PAYMENT_FAILED: 16030,
};

export const RECAPTCHA_KEY = '6LeieRApAAAAAEvyK73OuiYYzQ67ImzOLVv50mkG'; 
//fake one
// export const RECAPTCHA_KEY = '6LfYZ84iAAAAANey7kAR32ZL_YkiXoVNKmIOQQhD';

export const SERVER_STATUS = {
    5: 'Pending Payment',
    10: 'Provisioning',
    15: 'Running',
    20: 'Stopped',
    25: 'Rebooting',
};

export const STATUS_PENDING_PAYMENT = 5;
export const STATUS_PROVISIONING = 10;
export const STATUS_RUNNING = 15;
export const STATUS_STOPPED = 20;
export const STATUS_REBOOTING = 25;

export const GTM_ID = 'G-53CTZCX487';
