import React, {Fragment, useEffect} from "react";
import {ToastContainer, toast} from "react-toastify";
import {Link} from "react-router-dom";
import Media from "react-media";
import {useAppContext} from "../../context/app-context";
import 'react-toastify/dist/ReactToastify.css';
import VPS from "../common/VPS";
import {getCookie} from "../../config/utils";
import {getUsername} from "../../config/methods";
import variables from "../../assets/sass/variables.scss";
import screensVariables from '../../assets/sass/screensVariables.scss';
import routes from '../../config/routes';

//ARRANGED	

const Home = ({isLoggedIn}) => {
	const {isMobile} = useAppContext();
	// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	// const innerHeight = isSafari ? window.innerHeight : '100vh';

	useEffect(() => {
		if (isLoggedIn) {
			const toastLogin = getCookie('toast');
			if (toastLogin) {
				let name = getUsername()
				toast('Welcome, ' + name, {
					className: "toast-login",
					position: "bottom-center",
					autoClose: 5000,
					closeButton: false,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light"
				});
			}
		} 
		else {
			const toastLogout = getCookie('toast-logout');
			if (toastLogout) {
				toast('You have been logged out!', {
					className: "toast-logout",
					position: "bottom-center",
					autoClose: 5000,
					closeButton: false,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		}

	}, [isLoggedIn]);


	return (
		<div className="Home">
			<ToastContainer/>
			
			<Media            //MOBILE
				query={{maxWidth: variables.tabletMax}}
				defaultMatches={isMobile}
			>
				<Fragment>
					<section className="section-home">   
						<img 
							className="image"
							width={360}
							src="/images/home-hero2.png"  
							alt="hero"
						/>
						<div className="mouse-wrapper">
							<img src="/images/arrow-down2x.png" alt="arrow-down" width={29} height={12}/>
							<span className="mouse-text">Swipe up to explore</span>
						</div>
						<div className="left-column">
							<h1 className="title">
								Custom-Designed Cloud Solutions with <span className="luck has-text-sky">rm</span><span className="luck">BOTO</span><br/>
								<span className="is-size-4">Tailoring The Cloud To Your Business Needs</span>
							</h1>
							<p className="description-s">
								Welcome to RMBoto, we provide cloud services that are carefully designed to fulfil your unique business needs. 
								We understand that every business is different, which is why we offer innovative solutions to help achieve your specific objectives.
							</p>
						</div>
					</section>

					<VPS 
						isMobile={isMobile}
						isLoggedIn={isLoggedIn}	
					/>

					<section className="section-about">
						<h2 className="subtitle has-text-centered">
							About
							<span className="has-text-sky luck is-block">us</span>
						</h2>
						<div className="hero-text-wrapper">
							<img 
								width={450}
								className="mb-6"
								src="/images/about-hero.png"  
								alt="about-hero"
							/>
							<div className="has-text-right">
								<h3 className="subtitle">
									Perfect fit for your
									<span className="has-text-sky luck is-block">business</span>
								</h3>
								<p className="description">
									Why settle for one-size-fits-all when you can have a cloud 
									solution meticulously crafted to meet your specific needs? 
									From tailored storage capacities to customized security 
									protocols, we’ve got you covered.
								</p>
								<Link to={routes.about} className="button is-fit-content blue mt-3 ml-auto">Read more</Link>
							</div>
						</div>
					</section>
				</Fragment>
			</Media>

			<Media
				query={{minWidth: variables.desktopMin}}
				defaultMatches={!isMobile}
			>
				<Fragment>
					<section className="section home">   
						<div className="left-column is-half has-text-left">
							<h1 className="title">
								Custom-Designed Cloud Solutions with <span className="luck has-text-sky">rm</span><span className="luck">BOTO</span><br/>
								Tailoring The Cloud To Your Business Needs
							</h1>
							<p className="description-l">
								Welcome to RMBoto, we provide cloud services that are carefully designed to fulfil your unique business needs. We understand that every business is different, which is why we offer innovative solutions to help achieve your specific objectives.
							</p>
						</div>
						<div className="mouse-wrapper">
							<div className="mouse"/>
							<span className="mouse-text">Scroll to explore</span>
						</div>
						<div className="is-half is-flex is-justify-content-center">
							<img 
								className="image is-align-self-center"
								width={450}
								src="/images/home-hero2.png"  
								alt="hero"
							/>
						</div>
					</section>

					<VPS 
						isMobile={isMobile} 
						isLoggedIn={isLoggedIn}
					/>
					
					<section className="section about">
						<h2 className="subtitle">
							About
							<br />
							<span className="luck has-text-sky is-block">us</span>
						</h2>
						<div className="hero-text-wrapper">
							<img 
								width={450}
								src="/images/about-hero.png"  
								alt="about-hero"
							/>
							<div className="text-wrapper">
								<h3 className="subtitle">
									Perfect fit for your
									<span className="has-text-sky luck is-block">business</span>
								</h3>
								<p className="description-l mb-5">
									Why settle for one-size-fits-all when you can have a cloud 
									solution meticulously crafted to meet your specific needs? 
									From tailored storage capacities to customized security 
									protocols, we’ve got you covered.
								</p>
								<Link to={routes.about} className="button blue is-fit-content ml-auto">Read more</Link>
							</div>
						</div>
					</section>
				</Fragment>
			</Media>

			<style global jsx>{`
				.Home { 
					margin-top: ${variables.headerMargin};
					.home {
						position: relative; 
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: calc(100vh - ${variables.headerMargin});   
						background-color: ${variables.paleblue};
						background-image: url("/images/bgr.png");
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						padding: 1rem 4rem;
						.left-column {
							width: 40%;
							border-bottom-right-radius: 25px;
						}     
					}
					.products {
						background-color: white;
						.button {
							margin-top: 1.5rem;
						}
						.our-products {
							text-align: center;
						}
						.text {
							display: flex;
							flex-direction: column;
							justify-content: center;
						}
					}
					.about { 
						display: flex;
						flex-direction: column;
						height: fit-content;
						background-color: ${variables.paleblue};
						.hero-text-wrapper {
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 25vw;
							padding: 1rem 4rem;
							.text-wrapper { 
								text-align: right;
							}
						}
					}
					@media all and (max-width: ${screensVariables.mobileMax}) {
						.section-home {
							height: calc(100vh - ${variables.headerMargin});   
							background-color: ${variables.paleblue};
							background-image: url("/images/bgr.png");
							background-position: right bottom;
							padding: ${variables.mobilePadding}; 
							position: relative;
							.image {
								padding: 0rem 4rem;
							}
						}
						.section-about {
							height: 100%;
							padding: 1rem;
							margin-top: 2rem;
							text-align: center;
							background-color: ${variables.paleblue};
							.hero-text-wrapper {
								.text-wrapper {
									text-align: right;
								}
							}
						}
					}
				}  
			`}</style>
		</div>
	)
}
export default Home;