import React, {useState, useEffect, Fragment} from "react";
import classNames from 'classnames';
import AddCardIFrame from "../common/AddCardIFrame";
import CardPanel from "../common/CardPanel";
import variables from "../../assets/sass/variables.scss";
import {getAllCountries, getCard} from "../../config/ajax";
import {isEmpty} from '../../config/methods';

const UNITED_STATES = 233;

const CompleteOrder = () => {
    const [isLoadingAddCardBtn, setAddCardLoadingBtn] = useState(false);
    const [isVisibleAddCardModal, setAddCardVisibility] = useState(false);
    const [addCardParams, setAddCardParams] = useState({});
    const [countries, setCountries] = useState([]);
    const [toggleCompanyAddress, setToggleCompanyAddress] = useState(true);
    const [billingAddress, setBillingAddress] = useState({
        phone: "",
        city: "",   
        countryId: 0,
        address: "",
        company: "",
        vatId: ""
    });
    const [companyAddress, setCompanyAddress] = useState({
        companyPhone: "",
        companyCity: "",
        companyCountryId: "",
        companyAddress: ""
    });
    const [card, setCard] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getAllCountries()
            .then(res => {
                setCountries(res.data) 
            });

        getCard()
            .then((res) => {
                setCard(res.data.card);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const removeErrors = (e) => {
        setErrors({
          ...errors,
          [e.target.name]: ''
        })
    };

    const validateForm = () => {
        const obj = {};

        const isNum = /^[0-9]+$/;

        if (billingAddress.countryId === 0) {
            obj.countryId = "Country is required!";
        }
        if (billingAddress.city.length === 0) {
            obj.city = "City is required!";
        }
        if (billingAddress.address.length === 0) {
            obj.address = "Address number is required!";
        }
        if (billingAddress.phone.length === 0) {
            obj.phone = "Phone is required!";
        } else if (!isNum.test(billingAddress.phone)) {
            obj.phone = "Phone must content only numbers!";
        }

        if (!toggleCompanyAddress) { 
            if (billingAddress.company.length === 0) {
                obj.company = 'Company country is required!';
            }
            if (billingAddress.vatId.length === 0) {
                obj.vatId = 'Company country is required!';
            }
            if (companyAddress.companyCountryId === 0) {
                obj.companyCountryId = 'Company country is required!';
            } 
            if (companyAddress.companyCity.length === 0) {
                obj.companyCity = 'Company city is required!'
            }
            if (companyAddress.companyAddress.length === 0) {
                obj.companyAddress = 'Company address is required!';
            }
            if (companyAddress.companyPhone.length === 0) {
                obj.companyPhone = "Company phone is required!";
            } else if (!isNum.test(companyAddress.companyPhone)) {
                obj.companyPhone = "Phone must content only numbers!";
            }
        }

        return obj
    }

    const handleInputChange = e => {
        const {name, value} = e.target;
    
        setBillingAddress({
          ...billingAddress,
          [name]: value,
        });
    };

    const handleCompanyInputChange = e => {
        const {name, value} = e.target;
    
        setCompanyAddress({
          ...companyAddress,
          [name]: value,
        });
    };
    
    const handleToggleCompanyAddress = () => setToggleCompanyAddress(!toggleCompanyAddress);

    const handleSubmit = () => {
        if (toggleCompanyAddress) {
            setCompanyAddress({
                companyPhone: billingAddress.phone,
                companyCity: billingAddress.city,
                companyCountryId: billingAddress.countryId,
                companyAddress: billingAddress.address
            });
        }

        const errors = validateForm();

        

        if (!isEmpty(errors)) {
            setErrors(errors);
        } else {
            console.log('няма грешки');
        }
    };

    const getCreditCard = () => {
        getCard()
            .then((res) => {
                setCard(res.data.card);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const closeAddCard = () => setAddCardVisibility(false);

    const countryInputClasses = classNames("input", {
        "is-danger": errors.countryId
    });

    const cityInputClasses = classNames("input", {
        "is-danger": errors.city
    });

    const addressInputClasses = classNames("input", {
        "is-danger": errors.address
    });

    const phoneInputClasses = classNames("input", {
        "is-danger": errors.phone
    });

    const companyInputClasses = classNames("input", {
        "is-danger": errors.company
    });

    const vatIdInputClasses = classNames("input", {
        "is-danger": errors.vatId
    });

    const companyCountryInputClasses = classNames("input", {
        "is-danger": errors.companyCountryId
    });

    const companyCityInputClasses = classNames("input", {
        "is-danger": errors.companyCity
    });

    const companyAddressInputClasses = classNames("input", {
        "is-danger": errors.companyAddress
    });

    const companyPhoneInputClasses = classNames("input", {
        "is-danger": errors.companyPhone
    });

    return (
        <div className="CompleteOrder">  
            {isVisibleAddCardModal && (
                <AddCardIFrame 
                    closeDialog={closeAddCard}
                    getCard={getCreditCard}
                    addCardParams={addCardParams}
                    isModal
                />
            )}

            <section className="section">
                <div className="container"> 
                    <h1 className="title has-text-centered">
                        Billing
                        <div className="has-text-sky luck is-block">Details</div>
                    </h1>

                    <CardPanel
                        card={card}
                        setAddCardParams={setAddCardParams}
                        setAddCardVisibility={setAddCardVisibility}
                        setAddCardLoadingBtn={setAddCardLoadingBtn}
                        isLoadingAddCardBtn={isLoadingAddCardBtn}
                    />

                    <div className="form">
                        <div className="field">
                            <div className="select">
                                <label htmlFor="countryId" className="label">Country</label>
                                <select
                                    onChange={handleInputChange}
                                    onFocus={removeErrors}
                                    defaultValue={billingAddress.countryId}
                                    className={countryInputClasses}
                                    name="countryId"
                                    id="countryId"
                                    required
                                >
                                    <option 
                                        disabled 
                                        value={0} 
                                    >
                                        Select your country
                                    </option>
                                    {countries?.map((country) => (
                                        <option 
                                            key={country.id}
                                            value={country.id}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {errors.countryId && (<span className="errors">{errors.countryId}</span>)}
                        </div>
                        {+billingAddress.countryId === UNITED_STATES && (
                            <div className="is-flex is-align-items-center has-gap-2">
                                <div className="select">
                                    <label className="label">State</label>
                                    <select
                                        onChange={handleInputChange}
                                        className="input"
                                        name="country" 
                                        id="country" 
                                        required
                                    >
                                        <option 
                                            value="Select" 
                                            className="select-placeholder"
                                        >
                                            Select state
                                        </option>
                                        <option 
                                            value={1}
                                            className="select-placeholder"
                                        >
                                            {1}
                                        </option>
                                        <option 
                                            value={2}
                                            className="select-placeholder"
                                        >
                                            {2}
                                        </option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label className="label">Zip Code</label>
                                    <div className="control">
                                        <input
                                            onChange={handleInputChange}
                                            className="input"
                                            name="zip" 
                                            id="zip"
                                            placeholder="Enter ZIP code"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="field">
                            <label htmlFor="city" className="label">City</label>
                            <div className="control">
                                <input
                                    onChange={handleInputChange}
                                    onFocus={removeErrors}
                                    value={billingAddress.city}
                                    className={cityInputClasses}
                                    name="city"
                                    id="city" 
                                    placeholder="Enter City"
                                    required
                                />
                                {errors.city && (<span className="errors">{errors.city}</span>)}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="address" className="label">Address line</label>
                            <div className="control">
                                <input
                                    onChange={handleInputChange}
                                    onFocus={removeErrors}
                                    className={addressInputClasses}
                                    name="address"
                                    id="address" 
                                    placeholder="Enter your address"
                                    required
                                />
                                {errors.address && (<span className="errors">{errors.address}</span>)}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="phone" className="label">Phone number</label>
                            <div className="control">
                                <input
                                    onChange={handleInputChange}
                                    onFocus={removeErrors}
                                    className={phoneInputClasses}
                                    name="phone"
                                    id="phone" 
                                    placeholder="Enter your number"
                                    required
                                />
                                {errors.phone && (<span className="errors">{errors.phone}</span>)}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="company" className="label">Company {toggleCompanyAddress && '(optional)'}</label>
                            <div className="control">
                                <input
                                    onChange={handleInputChange}
                                    onFocus={removeErrors}
                                    className={companyInputClasses}
                                    name="company"
                                    id="company" 
                                    placeholder="Enter company name"
                                />
                                {errors.company && (<span className="errors">{errors.company}</span>)}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="vatId" className="label">TAX/VAT ID {toggleCompanyAddress && '(optional)'}</label>
                            <div className="control">
                                <input
                                    onChange={handleInputChange}
                                    onFocus={removeErrors}
                                    className={vatIdInputClasses}
                                    name="vatId"
                                    id="vatId" 
                                    placeholder="Enter TAX/VAT ID"
                                />
                                {errors.vatId && (<span className="errors">{errors.vatId}</span>)}
                            </div>
                        </div>
                        {!toggleCompanyAddress && (
                            <Fragment>
                                <div className="is-size-3-half">Company Address</div>
                                <div className="field">
                                    <div className="select">
                                        <label htmlFor="companyCountryId" className="label">Company country</label>
                                        <select
                                            onChange={handleCompanyInputChange}
                                            onFocus={removeErrors}
                                            className={companyCountryInputClasses}
                                            name="companyCountryId"
                                            id="companyCountryId"
                                            required
                                        >
                                            <option 
                                                value="Select" 
                                            >
                                                Select your country
                                            </option>
                                            {countries?.map((country) => (
                                                <option 
                                                    key={country.id}
                                                    value={country.id}
                                                >
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.companyCountryId && (<span className="errors">{errors.companyCountryId}</span>)}
                                    </div>
                                </div>
                                {+billingAddress.countryId === UNITED_STATES && (
                                    <div className="is-flex has-gap-2">
                                        <div className="select">
                                            <label htmlFor="companyCountry" className="label">State</label>
                                            <select
                                                onChange={handleCompanyInputChange}
                                                className="input"
                                                name="companyCountry" 
                                                id="companyCountry" 
                                                required
                                            >
                                                <option 
                                                    value="Select" 
                                                >
                                                    Select state
                                                </option>
                                                <option 
                                                    value={1}
                                                >
                                                    {1}
                                                </option>
                                                <option 
                                                    value={2}
                                                >
                                                    {2}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="field">
                                            <label>Zip Code</label>
                                            <div className="control">
                                                <input
                                                    onChange={handleCompanyInputChange}
                                                    className="input"
                                                    name="companyZip" 
                                                    id="companyZip"
                                                    placeholder="Enter ZIP code"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="field">
                                    <label htmlFor="companyCity" className="label">Company city</label>
                                    <div className="control">
                                        <input
                                            onChange={handleCompanyInputChange}
                                            onFocus={removeErrors}
                                            className={companyCityInputClasses}
                                            name="companyCity"
                                            id="companyCity" 
                                            placeholder="Enter City"
                                            required
                                        />
                                        {errors.companyCity && (<span className="errors">{errors.companyCity}</span>)}
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="companyAddress" className="label">Company address line</label>
                                    <div className="control">
                                        <input
                                            onChange={handleCompanyInputChange}
                                            onFocus={removeErrors}
                                            className={companyAddressInputClasses}
                                            name="companyAddress"
                                            id="companyAddress" 
                                            placeholder="Enter your address"
                                            required
                                        />
                                        {errors.companyAddress && (<span className="errors">{errors.companyAddress}</span>)}
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="companyPhone" className="label">Company phone number</label>
                                    <div className="control">
                                        <input
                                            onChange={handleCompanyInputChange}
                                            onFocus={removeErrors}
                                            className={companyPhoneInputClasses}
                                            name="companyPhone"
                                            id="companyPhone" 
                                            placeholder="Enter your number"
                                            required
                                        />
                                        {errors.companyPhone && (<span className="errors">{errors.companyPhone}</span>)}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        <div className="button-wrapper">
                            <div className="is-flex is-fullwidth">
                                <input 
                                    checked={toggleCompanyAddress}
                                    onChange={handleToggleCompanyAddress}
                                    id="company-address" 
                                    type="checkbox" 
                                    name="switchRoundedDefault" 
                                    className="switch is-rounded" 
                                />
                                <label htmlFor="company-address">Company address same as billing</label>
                            </div>

                            <div
                                className="button blue is-large"
                                onClick={handleSubmit}
                            >
                                SAVE
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <style global jsx>{`
                .CompleteOrder {
                    background-color: ${variables.paleblue};
                    margin-top: ${variables.headerMargin};
                    flex-grow: 1;
                    display: flex;
                    justify-content: center;
                    .container {
                        gap: 2rem;
                    }
                    .section {
                        display: flex;
                        justify-content: center;
                        max-width: 33.5rem;
                        .form {
                            min-width: 32.5rem;
                            .select {
                                min-width: 15rem;
                                width: 100%;
                            }
                        }
                        @media all and (max-width: ${variables.mobileMax}) {
							min-width: 100%;
                            .container {
                                max-width: 100%;
                            }
                            .select {
                                min-width: auto;
                            }
                            .form {
                                min-width: 100%;
                            }
						}
                    }
                    .switch[type=checkbox]:checked + label::before, .switch[type=checkbox]:checked + label:before {
                        background: ${variables.sky};
                    }   
                    .button.blue.is-large {
                        align-self: center;
                        width: fit-content;
                    }
                    .has-gap-2 {
                        gap: 1rem;
                    }
                    .add-card {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 2rem;
                        margin-block: 2rem;
                        color: #302D2C;
                        border-radius: 2rem;
                        background-color: #F4F7FA;
                        box-shadow: 20px 20px 35px rgba(71, 168, 189, 0.2), -20px -20px 35px #ffffff, inset 0px 0px 0px rgba(71, 168, 189, 0.2), inset 0px 0px 0px #ffffff;
                        .creditCard {
                            width: 3rem;
                            height: 2.25rem;
                            background-image: url('/images/credit-card.svg');
                        }
                    }
                    .button-wrapper {
                        display: flex; 
                        align-items: center;
                        @media all and (max-width: ${variables.mobileMax}) {
                            flex-direction: column;
                            gap: 1rem;
                        }
                    }
                }
            `}</style>
        </div>
    )
}
export default CompleteOrder;