import React, {Fragment} from "react";
import Media from "react-media";
import VPS from "../common/VPS";
import {useAppContext} from "../../context/app-context";
import screenVariables from '../../assets/sass/screensVariables.scss';
import variables from  "../../assets/sass/variables.scss";

const About = (props) => {
    const {isLoggedIn} = props;
    const {isMobile} = useAppContext();

    return (
        <div className="About">
            <Media            //MOBILE
                query={{maxWidth: variables.tabletMax}}
                defaultMatches={isMobile}
            >
				<Fragment>
                    <section className="section">
                        <div className="container about">
                            <h1 className="title has-text-centered">
                                About
                                <span className="has-text-sky luck is-block">us</span>
                            </h1>
                            <div className="hero-text-wrapper">
                                <div className="text-wrapper">
                                    <h2 className="subtitle has-text-left">
                                        Perfect fit for your
                                        <span className="has-text-sky luck is-block">business</span>
                                    </h2>
                                    <p className="description-l">
                                        Why settle for one-size-fits-all when you can have a cloud 
                                        solution meticulously crafted to meet your specific needs? 
                                        From tailored storage capacities to customized security 
                                        protocols, we’ve got you covered.
                                    </p>
                                </div>
                                <div className="photo-wrapper">
                                    <img 
                                        className="photo"
                                        src="/images/about-hero1.png"  
                                        width={500}
                                        height={400}
                                        alt="about-hero"
                                    />
                                </div>
                            </div>
                            <div className="centered has-text-left mt-4">
                                The Cloud, Your Way <br/>
                                Isn’t it time you stopped adapting to the technology and 
                                started having technology adapted to you? At RMBoto, 
                                we design the cloud around your business, not the other way around.
                            </div>
                        </div>
                    </section>
					<VPS 
                        isMobile={isMobile} 
                        isLoggedIn={isLoggedIn}    
                    />
				</Fragment>
			</Media>

			<Media
				query={{minWidth: variables.desktopMin}}
				defaultMatches={!isMobile}
			>
				<Fragment>
                    <section className="section">
                        <h1 className="title has-text-centered">
                            About
                            <span className="has-text-sky luck is-block">us</span>
                        </h1>
                        <div className="hero-text-wrapper">
                            <div className="text-wrapper">
                                <span className="has-text-marine is-size-1">Perfect fit for your</span>
                                <span className="has-text-sky is-size-2 luck">business</span>
                                <p className="description-l">
                                    Why settle for one-size-fits-all when you can have a cloud 
                                    solution meticulously crafted to meet your specific needs? 
                                    From tailored storage capacities to customized security 
                                    protocols, we’ve got you covered.
                                </p>
                            </div>
                            <div className="photo-wrapper">
                                <img 
                                    className="photo"
                                    src="/images/about-hero1.png"  
                                    width={500}
                                    height={400}
                                    alt="about-hero"
                                />
                            </div>
                        </div>
                        <div className="centered has-text-centered mt-6">
                            The Cloud, Your Way <br/>
                            Isn’t it time you stopped adapting to the technology and 
                            started having technology adapted to you? At RMBoto, 
                            we design the cloud around your business, not the other way around.
                        </div>
                    </section>
					<VPS 
                        isMobile={isMobile} 
                        isLoggedIn={isLoggedIn}
                    />
				</Fragment>
			</Media>

            <style global jsx>{`
                .About {
                    margin-top: ${variables.headerMargin};
                    background: ${variables.paleblue}; 
                    .section {
                        background-color: ${variables.paleblue};
                        .hero-text-wrapper {
                            display: flex;
                            justify-content: space-between;
                            gap: 5rem;
                            .text-wrapper { 
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                justify-content: center;
                                width: 50%;
                                text-align: left;
                            }
                        }
                        .photo-wrapper {
                            width: 50%;
                            display: flex;
                            justify-content: center;
                            align-content: center; 
                        }
                        hr {
                            display: flex; 
                            justify-content: center;
                        }    
                    }
                }
                @media all and (min-width: ${screenVariables.tabletMin}) and (max-width: ${screenVariables.tabletMax}) { 
                    .About {
                        .about {
                            .about-us {
                                padding: 1rem 0rem 2rem 0rem;
                            }
                            .columns{
                                .about-text {
                                    padding: 0rem 4rem 0rem 4rem;
                                    .big-text-wrapper {
                                        line-height: 1.5 !important;
                                        margin: 2rem 0rem 2rem 0rem;
                                    }
                                }   
                                .about-logo {
                                    img {
                                        width: 25rem;
                                        position: relative;
                                        top: 0% !important;
                                        left: -25% !important;
                                    }
                                }
                            }
                            .biggest-text {
                                padding: 2rem;
                            }
                        }
                    }
                }
                @media all and (max-width: ${screenVariables.mobileMax}) { 
                    .About {
                        .section {
                            .about {
                                .hero-text-wrapper {
                                    flex-direction: column-reverse;
                                    gap: 0;
                                    .text-wrapper {
                                        align-items: flex-start;
                                        width: 100%;
                                    }
                                    .photo-wrapper {
                                        width: 100%;
                                        padding: 0 2rem;
                                    }
                                }
                            }
                        }
                    }
                }
            `}</style>      
        </div>
    )
} 
export default About;