import React, {Fragment, useEffect, useState} from "react";
import Media from "react-media";
import {ToastContainer} from "react-toastify";
import {useAppContext} from "../../../context/app-context";
import VPSTables from "./VPSTables";
import 'react-toastify/dist/ReactToastify.css';
import screensVariables from '../../../assets/sass/screensVariables.scss';
import variables from "../../../assets/sass/variables.scss";
import {getProducts} from "../../../config/ajax";

const Products = () => {
    const [products, setProducts] = useState([]);
    const {isMobile, isTablet} = useAppContext();

    useEffect(() => {
        getProducts()
            .then((res) => {
                setProducts(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <div className="Products">    
            <ToastContainer/>

			<Media            //MOBILE
				query={{maxWidth: variables.tabletMax}}
				defaultMatches={isMobile}
			>
                <Fragment>
                    <section className="section-products">
                        <h1 className="title has-text-centered">
                            our
                            <div className="has-text-sky luck is-block">Products</div>
                        </h1> 
                        <img 
                            src="/images/products-hero.png"
                            alt="hero-2" 
                            className="is-block mx-auto"
                            width={250}
                        />
						<div className="mouse-wrapper">
							<img src="/images/arrow-down2x.png" alt="arrow-down" width={29} height={12}/>
							{/* <span className="mouse-text">Swipe up to explore</span> */}
						</div>
                        <p className="description-s">
                            VPS or Virtual Private Server hosting separates a physical server into multiple virtual machines, 
                            providing users with dedicated resources and full root access to the web server. 
                            As a result, VPS hosting plans offer greater flexibility and power than shared hosting.
                            With our VPS machines, you have complete control to customize the operating system, 
                            server management control panel, and software to your needs. 
                            While you take full responsibility for the virtual server, 
                            the web host will maintain the physical servers to guarantee speed, high uptime, and stability.
                        </p>   
                    </section>
                    <VPSTables 
                        isMobile={isMobile} 
                        isTablet={isTablet} 
                        products={products}    
                    />
                </Fragment>
			</Media>

			<Media
				query={{minWidth: variables.desktopMin}}
				defaultMatches={!isMobile}
			>
                <Fragment>
                    <section className="section section-products">
                        <div className="left-column">
                            <h1 className="title has-text-centered">
                                <span className="our">our</span>
                                <div className="has-text-sky luck is-block">Products</div>
                            </h1> 
                            <p className="description-l">
                                VPS or Virtual Private Server hosting separates a physical server into multiple virtual machines, 
                                providing users with dedicated resources and full root access to the web server. 
                                As a result, VPS hosting plans offer greater flexibility and power than shared hosting.
                                With our VPS machines, you have complete control to customize the operating system, 
                                server management control panel, and software to your needs. 
                                While you take full responsibility for the virtual server, 
                                the web host will maintain the physical servers to guarantee speed, high uptime, and stability.
                            </p> 
                        </div>                               
                        <div className="mouse-wrapper">
                            <div className="mouse"/>
                            <span className="mouse-text">Scroll to explore</span>
                        </div>
                        <img 
                            className="hero2-logo"
                            src="/images/products-hero.png"
                            alt="hero-2" 
                            width="600"
                            height="500"
                        />
                    </section>

                    <VPSTables
                        isMobile={isMobile}
                        products={products}
                        isTablet={isTablet} 
                    />
                </Fragment>
			</Media>

            <style global jsx>{`
                .Products {
					margin-top: ${variables.headerMargin}; 
                    .section {
                        &.section-products {
                            background: ${variables.paleblue};
                            height: calc(100vh - ${variables.headerMargin});
                            padding: ${variables.desktopPadding};
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .left-column {
                                width: 40%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                .our {
                                    position: relative;
                                    top: 0;
                                    left: -50%;
                                }
                            }
                            .hero2-logo {
                                width: 40%;
                                max-width: 500px;
                            }
                        }
                    }
                    .link {
                        color: white;
                    }
                    @media all and (max-width: ${screensVariables.mobileMax}) {
                        .section-products {
							height: calc(100vh - 2*${variables.headerMargin});     
                            background: ${variables.paleblue};
                            padding: ${variables.mobilePadding};
                            image {
                                padding: 0rem 2.5rem;
                            }
                            .description-s {
                                max-height: 23%;
                                overflow-y: scroll;
                            }
                        }
                    }
                }
                /* @media all and (min-width: ${screensVariables.tabletMin}) and (max-width: ${screensVariables.tabletMax}) {
                    .Products {
                        width: 100%;
                        min-width: 100%;
                        .section {
                            padding: 0rem 1.5rem 0rem 1.5rem;
                        }
                        .products {
                            .left-column {
                                .our-products {
                                    text-align: center;
                                    padding: 2rem 0rem 1rem 0rem; 
                                    .our {
                                        top: 1.5rem;
                                        left: 15.5rem;
                                    }
                                    .labels {
                                        margin-top: 2rem;
                                        margin-left: 1rem;
                                    }
                                }
                            }
                            .hero2-logo {
                                margin-left: 0;
                                padding: 5rem;
                            }
                        }
                    }
                } */
            `}</style>
        </div>
    )
} 
export default Products;